import React from 'react'

import LoadingIndicator from './LoadingIndicator'
import { LinearProgress, CircularProgress, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PickList from './PickList'
import PropTypes from 'prop-types'
import { fetchPickTasks } from '../../../Services/api/index'
import { timeFormatter } from '../../../Services/util/index'
export class PicklistResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stores: [],
      pickTasks: {},
      displayOrders: false, // todo: optimize this flags
      finishedRendering: false,
      startLoading: false,
      displayError: false,
      storeNumber: '',
      orderType: '',
      timestamp: ''
    }
  }

  componentDidMount() {
    const {storeNumber, orderType} = this.props
    return this.loadPicklistForStoreAndOrderType(storeNumber, orderType)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeNumber !== this.props.storeNumber || prevProps.orderType !== this.props.orderType) {
      const {storeNumber, orderType} = this.props
      return this.loadPicklistForStoreAndOrderType(storeNumber, orderType)
    }
  }

  filterNDSTSOrders = (department, orderType) => {
    if (!department || !department.items || !orderType) return department 

    const filteredDepartment = {...department}
    if (orderType === 'WEB') {
      // REMOVE orders that has promiseType 'SHIP_TO_STORE'
      filteredDepartment.items = filteredDepartment.items.filter(item => !item.promiseType || (item.promiseType && item.promiseType !== 'SHIP_TO_STORE'))
    } else if (orderType === 'NDSTS') {
      // KEEP ONLY orders that has promiseType 'SHIP_TO_STORE'
      filteredDepartment.items = filteredDepartment.items.filter(item => item.promiseType && item.promiseType === 'SHIP_TO_STORE')
    } else {
      return department
    }

    // update item count for the department
    filteredDepartment.count = filteredDepartment.items.length
    // get unique order count for the department
    const orderSet = new Set()
    filteredDepartment.items.forEach(item => orderSet.add(item.orderId))
    filteredDepartment.orderCount = orderSet.size.toString() 
    return filteredDepartment
  }

  /* 
  * Remove or keep only NDSTS orders from WEB order type object 
  * @param {Object} orderTypeDataObject - WEB orderType data object part of responseData
  * @param {String} orderType - orderType to filter the responseData
  * 
  * @return {Object} updatedOrderTypeDataObject - updated WEB OR NDSTS orderType data object
  */
  updateOrderTypeDataObject = (orderTypeDataObject, orderType) => {
    const updatedOrderTypeDataObject = {...orderTypeDataObject}
    const departments = updatedOrderTypeDataObject.departments
    if (departments) {
      // REMOVE or KEEP ONLY orders that has promiseType as SHIP_TO_STORE based on orderType
      updatedOrderTypeDataObject.departments = departments
        .map(department => this.filterNDSTSOrders(department, orderType))
        .filter(department => department.count > 0 && department.orderCount !== '0')
      
      // update other data fields based on the filtered data
      updatedOrderTypeDataObject.count = updatedOrderTypeDataObject.departments.reduce((acc, department) => acc + department.count, 0)
      if (orderType === 'NDSTS') updatedOrderTypeDataObject.name = 'NEXT DAY STS'
      
      return updatedOrderTypeDataObject
    }

    return orderTypeDataObject
  }

  /* 
  * Partition orderTypes data object returned from service API into WEB and NDSTS orders when orderType is WEB
  * @param {Object} orderTypeDataObject - WEB orderType data object part of responseData
  * 
  * @return {Array} partitionedOrderTypeDataObject - partitioned WEB and NDSTS orders
  */
  partitionOrderTypeDataObject = (orderTypeDataObject) => {
    if (!orderTypeDataObject || orderTypeDataObject.name !== 'WEB') return orderTypeDataObject
    const partitionedWebOrders = this.updateOrderTypeDataObject(orderTypeDataObject, 'WEB')
    const partitionedNDSTSOrders = this.updateOrderTypeDataObject(orderTypeDataObject, 'NDSTS')
    return [partitionedWebOrders, partitionedNDSTSOrders]
  }
  
  /*
  * Update the responseData returned from service API 
  * @param {Object} responseData - response data from service API
  * @param {String} orderType - orderType to filter the responseData
  * 
  * @return {Object} updatedResponseData - updated response data based on the orderType,
  * removed NDSTS orders if orderType is WEB, keeped only NDSTS orders if orderType is NDSTS
  * and separated WEB and NDSTS orders if orderType is ALL
  */
  updateResponseData = (responseData, orderType) => {
    if (!responseData || !responseData.orderTypes || !responseData.orderTypes.length || !orderType) return responseData
    const updatedResponseData = {...responseData}
    if (orderType === 'WEB') {
      updatedResponseData.orderTypes[0] = this.partitionOrderTypeDataObject(responseData.orderTypes[0])[0]
    } else if(orderType === 'NDSTS') {
      updatedResponseData.orderTypes[0] = this.partitionOrderTypeDataObject(responseData.orderTypes[0])[1]
    } else if(orderType === 'ALL') {
      for (let i = 0; i < responseData.orderTypes.length; i++) {
        if (responseData.orderTypes[i].name === 'WEB') {
          const partitionedWebOrders = this.partitionOrderTypeDataObject(responseData.orderTypes[i])
          updatedResponseData.orderTypes[i] = partitionedWebOrders[0]
          updatedResponseData.orderTypes.push(partitionedWebOrders[1])
        }
      }
    } else {
      return responseData
    }

    updatedResponseData.count = updatedResponseData.orderTypes.reduce((acc, orderType) => acc + orderType.count, 0)
    return updatedResponseData
  }

  loadPicklistForStoreAndOrderType (storeNumber, orderType) {
    this.setState({ startLoading: true, finishedRendering: false, finishedFetching: false })
    return fetchPickTasks(storeNumber, orderType).then(response => {
      if (response.status === 200) {
        if (orderType === 'NDSTS' || orderType === 'WEB' || orderType === 'ALL') {
          response.data = this.updateResponseData(response.data, orderType)
        }
        this.setState({
          pickTasks: response.data,
          displayOrders: true,
          finishedFetching: true,
          finishedRendering: !response.data.count,
          timestamp: timeFormatter.getLocaltimestamp(new Date())
        })
      } else {
        this.setState({
          displayError: true,
          displayOrders: true,
          finishedFetching: true
        })
      }
    })
  }
  
  renderEmptyListMessage() {
    return <div className={this.props.classes.emptyListMessage}><strong>No orders found.</strong></div>
  }
  
  onFinishedRendering = () => {
    console.log('on finished loading called')
    this.setState({ finishedRendering: true })
  }
  
  renderOrders() {
    if (this.state.pickTasks.count === 0) {
      return this.renderEmptyListMessage()
    } else {
      const orderType = this.props.orderType === 'NDSTS' ? 'NEXT DAY STS' : this.props.orderType
      const pickListProps = {
        storeNumber: this.props.storeNumber,
        orderType: orderType,
        storeName: this.props.storeName,
        totalItemCount: this.state.pickTasks.count,
        pickTasks: this.state.pickTasks,
        onFinishedRendering: this.onFinishedRendering.bind(this),
        timestamp: this.state.timestamp
      }
      return <PickList {...pickListProps} />
    }
  }

  renderLoadingIndicator = () => {
    if (this.state.finishedRendering) {
      return <LinearProgress variant="determinate" value={100} />
    }

    if (this.state.startLoading && !this.state.finishedRendering) {
      return <Grid>
        <LoadingIndicator finishedFetching={this.state.finishedFetching} onFinishedLoading={this.onFinishedRendering.bind(this)}/>
        <Grid container item className={this.props.classes.root} justify="center" alignItems="center">
          <Grid item align="center" xs={12}>
            <CircularProgress />
            <div className={this.props.classes.spinningIndicator}>Loading results</div>
          </Grid>
        </Grid>
      </Grid>
    }

    if (!this.state.startLoading) {
      return <LinearProgress variant="determinate" value={0} />
    }
  }
  
  renderError () {
    if (this.state.displayError) {
      return (<div>Encountered error when fetching data</div>)
    }
    return null
  }
  render() {
    return(
      <div className={this.props.classes.container}>
        <div className={this.props.classes.linearIndicator}>
          <div className='not-printable'>{this.renderLoadingIndicator()}</div>
        </div>
        <div>
          {this.renderError()}
        </div>
        {(this.state.displayOrders && this.state.finishedFetching)?this.renderOrders() : null}
      </div>
    )
  }
}

PicklistResults.propTypes = {
  storeNumber: PropTypes.string,
  orderType: PropTypes.string,
  storeName: PropTypes.string
}
const styles = theme => ({
  container: {
    ...theme.typography.container,
    flexDirection: 'column'
  },
  linearIndicator: {
    marginTop: theme.spacing(31)
  },
  emptyListMessage: {
    ...theme.typography.text.primary,
    marginTop: theme.spacing(19)
  },
  root: {
    flexGrow: 1,
    height: theme.spacing(400),
    textAlign: 'center'
  },
  spinningIndicator: {
    padding: theme.spacing(10)
  }
})

export default withStyles(styles)(PicklistResults)