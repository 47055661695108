import axios from 'axios'
import config from '../config/'
import Vars from '../util/vars'
import storeFallbackList from './storesFallback.json'

export const orderTypes = ['ALL', 'BOPUS', 'NDSTS', 'SDD', 'WEB', 'FREE NEXT DAY DELIVERY (NSHIP)']
export const ORDER_TYPE_LABELS = ['All Orders', 'BOPUS', 'Next Day STS', 'SDD', 'Web', 'Free Next Day Delivery (NSHIP)']
const ORDER_TYPE_LABELS_LEGACY_DTC = ['All Orders (Except Legacy DTCs)', 'BOPUS', 'Next Day BOPUS', 'SDD', 'Web', 'Free Next Day Delivery (NSHIP)', '1D', 'Legacy DTCs']

const STORES_CACHE_KEY = 'Stores'
const LEGACY_DTC_FLAG_KEY = 'CI_LEGACY_DTC'

export async function getOrderTypeLabels () {
  const legacyDTC = await Vars.getVariableAsync(LEGACY_DTC_FLAG_KEY)
  return legacyDTC ? ORDER_TYPE_LABELS_LEGACY_DTC : ORDER_TYPE_LABELS
}

export async function fetchFullLineStores () {
  if (sessionStorage && sessionStorage.getItem(STORES_CACHE_KEY)) {
    return new Promise( (resolve) => {
      console.log('Got stores from cache')
      resolve(JSON.parse(sessionStorage.getItem(STORES_CACHE_KEY)))
    })
  }
  return axios.get(config.storeApi.url)
    .then(response => {
      let stores = []
      if (null != response.data) {
        response.data.stores.forEach(store => {
          if (store.type === 'FullLineStore' || store.type === 'Rack') {
            stores.push({ storeNumber: store.number, storeName: store.name })
          }
        })
      }
      sessionStorage.setItem(STORES_CACHE_KEY, JSON.stringify(stores))
      return stores
    }).catch(error => {
      console.error(error)
      return storeFallbackList
    })
}

export async function fetchPickTasks (storeNumber, orderType) {
  const onefillServiceUrl = config.onefillService.url
  const nshipIndex = orderType.indexOf('NSHIP')
  if (nshipIndex > 0) {
    //Send only the NSHIP piece to service endpoint
    orderType = orderType.substring(nshipIndex, nshipIndex+5)
  }
  // Next Day Ship To Store are WEB type orders with PROMISE_TYPE = 'SHIP_TO_STORE'
  if (orderType === 'NDSTS') {
    orderType = 'WEB'
  }
  return axios.get(`${onefillServiceUrl}/picklist?store=${storeNumber}&orderType=${orderType.replace(/ /g, '_').toUpperCase()}`, { crossdomain: true } )
}

export function isAnniversaryEnabled () {
  return true
}